import React from "react";
import PropTypes from "prop-types";
import GeometryCollection from "ol/geom/GeometryCollection";
import Collapsible from "../../component/Collapsible";
import Loading from "../../component/Loading/Loading";
import WKT from "ol/format/WKT";
import { translate } from "react-translate";
import {
  scopedBindActionCreators,
  digiThemeActions
} from "react-adaptive-utils";
import { connect } from "react-redux";
import { VectorSource, provideMapState } from "@avinet/react-openlayers";
import { getDistance } from "ol/sphere";
import { buffer } from "ol/extent";
import { Style, Icon, Text, Fill } from "ol/style";
import { Link } from "react-router-dom";
import { formatColumnValue } from "../../utils/date";
import container from "../../static/farligwback.svg";
import config from "../../config";

const mapStateToProps = state => ({
  eternitRecords: state.eternit.records,
  eternitLoading: state.eternit.loading,
  varhaustryddingrecords: state.varhaustrydding.records,
  varhaustryddingloading: state.varhaustrydding.loading
});

const mapDispatchToProps = dispatch =>
  scopedBindActionCreators(
    Object.assign({}, digiThemeActions),
    dispatch,
    "varhaustrydding"
  );
class MellombelsAvfallsmottak extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closest: undefined,
      eternitContainers: undefined,
      olFeature: undefined
    };
  }

  static propTypes = {
    address: PropTypes.object.isRequired,
    eternitRecords: PropTypes.array,
    eternitLoading: PropTypes.bool,
    fitViewAndZoom: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.readVarHaustRydding();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.address !== this.props.address) {
      this.readVarHaustRydding();
    }
  }

  readVarHaustRydding() {
    const { address } = this.props;
    const filter = config.themes.varhaustrydding.intersectFilter;
    filter.value =
      "SRID=" +
      config.mapProjCode +
      ";" +
      new WKT().writeFeature(address.olFeature);

    this.props.readFiltered(
      config.themes.varhaustrydding.columns,
      {
        filterColumns: [filter]
      },
      config.mapProjCode,
      0,
      1
    );
  }

  static getDerivedStateFromProps(props, state) {
    const { eternitRecords } = props;

    if (
      !props.varhaustryddingloading &&
      props.varhaustryddingrecords.length > 0 &&
      !state.olFeature
    ) {
      state.olFeature = new WKT().readFeature(
        props.varhaustryddingrecords[0].geom_wkt
      );
    }

    if (state.olFeature && eternitRecords.length > 0) {
      const ap = props.address.olFeature
        .getGeometry()
        .clone()
        .transform(config.mapProjection, "EPSG:4326");

      state.eternitContainers = eternitRecords.filter(r => {
        if (
          state.olFeature
            .getGeometry()
            .intersectsCoordinate(
              r.olFeature.getGeometry().getFirstCoordinate()
            )
        ) {
          return true;
        }
        return false;
      });

      state.eternitContainers = state.eternitContainers.map(r => {
        const p = r.olFeature
          .getGeometry()
          .clone()
          .transform(config.mapProjection, "EPSG:4326");
        r.distance = getDistance(p.getCoordinates(), ap.getCoordinates());
        return r;
      });

      state.eternitContainers.sort((a, b) => a.distance - b.distance);
    }

    if (props.varhaustryddingloading) {
      state.olFeature = undefined;
    }

    return state;
  }

  onExpand = () => {
    const { fitViewAndZoom, address } = this.props;
    const { eternitContainers } = this.state;
    let geoms = [address.olFeature.getGeometry()];

    if (eternitContainers && eternitContainers.length > 0) {
      eternitContainers.slice(0, 3).forEach(c => {
        geoms.push(c.olFeature.getGeometry());
      });
    }

    const extent = buffer(new GeometryCollection(geoms).getExtent(), 1.2);
    fitViewAndZoom(extent, config.fitViewAndZoom.in);
  };

  onCollapse = () => {
    const { fitViewAndZoom } = this.props;
    fitViewAndZoom(
      this.props.address.olFeature.getGeometry(),
      config.fitViewAndZoom.out
    );
  };

  featureStyle = f => {
    return new Style({
      image: new Icon({
        src: container,
        color: [255, 178, 102],
        scale: 1.6
      }),
      text: new Text({
        text: f.get("navn"),
        padding: [2, 2, 2, 2],
        offsetX: 50,
        offsetY: 25,
        backgroundFill: new Fill({ color: "rgba(255,255,255,.8)" }),
        scale: 1.4
      })
    });
  };

  render() {
    const { t, loading, varhaustryddingrecords } = this.props;
    const { eternitContainers, olFeature } = this.state;
    const closestEternit = eternitContainers && eternitContainers.slice(0, 3);
    console.log("mobile gjenvinningsstasjoner", {eternitContainers, varhaustryddingrecords});
    return varhaustryddingrecords && varhaustryddingrecords.length > 0 ? (
      <Collapsible
        className="hushald"
        header={
          loading ? (
            <React.Fragment>
              <span>{t("header")}</span>
              {loading && <Loading />}
            </React.Fragment>
          ) : (
            t("header")
          )
        }
        onExpand={this.onExpand}
        onCollapse={this.onCollapse}
        toggle={
          <React.Fragment>
            <VectorSource
              features={olFeature ? [olFeature] : []}
              layerName={
                "mellombels-zone-layer-" + varhaustryddingrecords[0].id
              }
              key={"mellombels-zone-layer-" + varhaustryddingrecords[0].id}
              selectable={false}
            />
            <VectorSource
              features={
                closestEternit
                  ? closestEternit.map(({ olFeature }) => olFeature)
                  : []
              }
              layerName={"eternit-layer-" + varhaustryddingrecords[0].id}
              key={"eternit-layer-" + varhaustryddingrecords[0].id}
              selectable={false}
              styleFn={() => this.featureStyle}
            />
          </React.Fragment>
        }
      >
        <>
          {closestEternit ? (
            closestEternit.map(c => (
              <div key={c.id} className="mellombels--content">
                <h4>{c.navn}</h4>
                <div>
                  <span>{t("hentedag")}: </span>
                  <span>{formatColumnValue(c.hdato, null, "date", "nb")}</span>
                </div>
                <div>
                  <span>{t("apningstid")}: </span>
                  <span>{c.tidsrom}</span>
                </div>
                <div>
                  <span>{t("kommentar")}: </span>
                  <span>{c.kommentar}</span>
                </div>
              </div>
            ))
          ) : (
            <div className="mellombels--no-results">{t("noResults")}</div>
          )}
        </>
        <div className="linkToMoreInfo">
          <Link to="/mobilgjenvinningsstasjon">{t("meirInfo")}</Link>
        </div>
      </Collapsible>
    ) : null;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  translate("MellombelsAvfallsmottak")(provideMapState(MellombelsAvfallsmottak))
);
